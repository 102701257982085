export class InputFormatter {
  constructor(patterns = {}) {
    this.patterns = {
      CPF: "###.###.###-##",
      CNPJ: "##.###.###/####-##",
      TELEFONE: "(##) #####-####",
      ...patterns,
    };
  }

  // Adiciona novo padrão
  addPattern(name, pattern) {
    if (typeof pattern !== "string") {
      throw new Error("Pattern must be a string");
    }
    this.patterns[name.toUpperCase()] = pattern;
  }

  // Remove padrão
  removePattern(name) {
    delete this.patterns[name.toUpperCase()];
  }

  // Obtém padrão específico
  getPattern(name) {
    return this.patterns[name.toUpperCase()];
  }

  // Lista todos os padrões
  getPatterns() {
    return { ...this.patterns };
  }

  // Limpa a string mantendo apenas letras e números
  clean(value) {
    return value.replace(/[^a-zA-Z0-9]/g, "");
  }

  // Formata o valor de acordo com o padrão
  format(value, patternName, name) {
    //busca pela patternName, que são as mascara pre-definidas, se não encontrar busca pelo name que é o uuid do campo customizado
    const pattern = this.getPattern(patternName) || this.getPattern(name);

    if (!pattern) {
      throw new Error(`Pattern "${patternName}" not found`);
    }

    const cleanValue = this.clean(value);
    let result = "";
    let valueIndex = 0;

    for (let i = 0; i < pattern.length && valueIndex < cleanValue.length; i++) {
      if (pattern[i] === "#") {
        result += cleanValue[valueIndex];
        valueIndex++;
      } else {
        result += pattern[i];
      }
    }

    return result;
  }

  // Retorna o tamanho máximo do padrão
  getMaxLength(patternName) {
    const pattern = this.getPattern(patternName);
    return pattern ? pattern.length : 0;
  }

  // Verifica se o valor está completo de acordo com o padrão
  isComplete(value, patternName) {
    const pattern = this.getPattern(patternName);
    if (!pattern) return false;

    const cleanValue = this.clean(value);
    const expectedLength = pattern
      .split("")
      .filter((char) => char === "#").length;
    return cleanValue.length === expectedLength;
  }
}
