import instance from "./Api";

export default class TeamService {
  getTeams(filtros, eventCode) {
    const config = {
      params: filtros,
    };
    return instance.get(
      this.host() + "/api/v1/event/" + eventCode + "/team?complete=false",
      config,
    );
  }
  getTeam(code, team) {
    return instance.get(`${this.host()}/api/v1/event/${code}/team/${team}`);
  }

  getTeamLogo(teamUuid) {
    return instance.get(this.host() + "/api/v1/team/" + teamUuid + "/logo", {
      responseType: "blob",
    });
  }

  joinATeam(request) {
    return instance.post(this.host() + "/api/v1/campaign/team/member", request);
  }

  editTeam(teamUuid, image, name, code) {
    const formData = new FormData();
    const request = {
      uuid: teamUuid,
      name: name,
    };

    formData.append("file", image);
    formData.append("team", JSON.stringify(request));

    return instance.post(`/api/v1/event/${code}/team`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  beALeader(request) {
    return instance.post(this.host() + "/api/v1/campaign/team/leader", request);
  }

  host() {
    return process.env.REACT_APP_HOST_API;
  }
}
