import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "react-dropdown-tree-select/dist/styles.css";
import { useSelector, useDispatch } from "react-redux";
import LeadershipRanking from "../../comps/rankings/LeadershipRanking";
import BotaoOpcoesRanking from "../../comps/rankings/BotaoOpcoesRanking";
import RankingEquipesService from "../../services/RankingEquipesService";
import { CampanhaRankingsStyled } from "./styled";
import RankingListaEquipes from "../../comps/rankings/RankingListaEquipes";
import RankingDaEquipe from "../../comps/rankings/RankingDaEquipe";
import RankingPaginacao from "../../comps/rankings/RankingPaginacao";
import RankingInputSearch from "../../comps/WeexInputs/RankingInputSearch";
import WeexSpinner from "../../comps/WeexSpinner/WeexSpinner";
import CampanhaService from "../../services/CampanhaService";
import { useNavigate } from "react-router-dom";

/**
 * Renderiza o componente de rankings de campanha por equipe.
 *
 * @param {Object} props - As propriedades do componente.
 * @param {boolean} [props.isRenderedInSchedulePage=false] - Indica se o componente está sendo renderizado na página de atividades.
 * @param {boolean} [props.isRenderedInHomePage=false] - Indica se o componente está sendo renderizado na página inicial.
 * @returns {React.Component} O componente de rankings de campanha por equipe.
 */

export default function CampanhaRankingsPorEquipe({
  isRenderedInSchedulePage = false,
  isRenderedInHomePage = false,
}) {
  const [buttonOptions, setButtonOptions] = useState([
    "todasAsEquipes",
    "suaEquipe",
  ]);
  const dispatch = useDispatch();

  const i18n = useSelector((state) => state.i18n);

  const navigate = useNavigate();
  const rankingEquipesService = useMemo(() => new RankingEquipesService(), []);

  const [loading, setLoading] = useState(false);
  const [rankings, setRankings] = useState([]);
  const [nomeEquipe, setNomeEquipe] = useState("");
  const [uuidEquipe, setUuidEquipe] = useState("");
  const [pontuacaoEquipe, setPontuacaoEquipe] = useState(0);
  const [membersFiltered, setMembersFiltered] = useState([]);
  const [members, setMembers] = useState([]);
  const [numberPages, setNumberPages] = useState(0);
  const [paginacao, setPaginacao] = useState({
    page: 0,
    numberPages: 0,
    search: "",
  });
  const [changePage, setChangePage] = useState(false);
  const [topRanking, setTopRanking] = useState([]);

  const initialControl = isRenderedInHomePage ? "suaEquipe" : "todasAsEquipes";

  const [control, setControl] = useState(initialControl);

  const campanha = new CampanhaService().campanhaCorrente();

  useEffect(() => {
    if (campanha) {
      if (campanha.personalizationEvent) {
        const ocultarRanking =
          campanha.personalizationEvent?.HIDE_RANKING === "true";
        if (ocultarRanking) {
          navigate("/atividades");
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onComponenteMount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEquipesRankingEquipes = useCallback(async () => {
    setLoading(true);
    const filter = {
      page: paginacao.page,
      pageSize: 20,
      teamName: paginacao.search,
    };
    rankingEquipesService.getRankingEquipes(filter).then((response) => {
      const { rankingTeams } = response.data;
      setNumberPages(response.data.numberPages);
      setRankings(
        rankingTeams.map((ranking) => {
          const rankingForLeadership = { ...ranking };
          rankingForLeadership["rating"] = ranking.score;
          return rankingForLeadership;
        }),
      );
      setLoading(false);
    });
  }, [paginacao, rankingEquipesService]);

  useEffect(() => {
    if (!changePage) {
      return;
    }
    getEquipesRankingEquipes();
  }, [paginacao, changePage, getEquipesRankingEquipes]);

  useEffect(() => {
    // Verifica se está na primeira página após o estado ser atualizado
    if (paginacao.page === 0 && paginacao.search === "") {
      setTopRanking(rankings.slice(0, 3));
    }
  }, [rankings, paginacao.page, paginacao.search]);

  const scrollRef = useRef(null);

  useLayoutEffect(() => {
    // Rolagem suave para o elemento específico na página principal
    if (paginacao.page === 0) {
      return;
    }
    if (scrollRef.current) {
      // Rola o scroll da página principal até este elemento
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
      // Ajusta o scroll da div interna para o topo
      scrollRef.current.scrollTop = 0;
    }
  }, [paginacao.page, rankings]);

  async function onComponenteMount() {
    try {
      setLoading(true);
      await getEquipesRankingEquipes();
      await getMyTeam();
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  const proximaPagina = () => {
    setChangePage(true);
    setPaginacao((prevPaginacao) => ({
      ...prevPaginacao,
      page: prevPaginacao.page + 1,
    }));
  };

  const paginaAnterior = () => {
    setChangePage(true);
    setPaginacao((prevPaginacao) => ({
      ...prevPaginacao,
      page: prevPaginacao.page - 1,
    }));
  };

  const changeSearch = (search) => {
    setChangePage(true);
    setPaginacao((prevPaginacao) => ({
      ...prevPaginacao,
      search: search,
      page: 0,
    }));
  };

  const getMyTeam = async () => {
    try {
      const team = await rankingEquipesService.getMyTeam();
      const { uuid, name, members, score } = team.data;
      setNomeEquipe(name);
      setUuidEquipe(uuid);
      setPontuacaoEquipe(score);
      setMembers(members);
      setMembersFiltered(members);
    } catch (error) {
      if (error.code === "NOT_HAS_TEAM") {
        setButtonOptions([
          ...buttonOptions.filter((option) => option !== "suaEquipe"),
        ]);
      }
      console.error(error);
    }
  };

  const flexOpcoesControl = (options) => {
    return options.map((option) => {
      return (
        <BotaoOpcoesRanking
          funcao={() => {
            setControl(option);
          }}
          controlValue={control}
          tag={option}
          disabledOnClick={false}
        />
      );
    });
  };

  const buscarParticipanteEquipe = async (search) => {
    if (!!search) {
      try {
        const response = members.filter((ranking) =>
          ranking.name.toLowerCase().includes(search.trim().toLowerCase()),
        );

        setMembersFiltered(response);
      } catch (error) {
        if (error?.error?.message) {
          dispatch({ type: "SHOW_MODAL", payload: "error" });
          dispatch({
            type: "error",
            payload: {
              title: i18n.message(
                "ranking.erro.buscar.participante.titulo",
                "Erro ao buscar participante",
              ),
              message: error.error.message,
            },
          });
          return;
        }
        dispatch({ type: "SHOW_MODAL", payload: "error" });
        dispatch({
          type: "error",
          payload: {
            title: i18n.message(
              "ranking.erro.buscar.participante.titulo",
              "Erro ao buscar participante",
            ),
            message: i18n.message(
              "ranking.erro.buscar.participante.mensagem",
              "Entre em contato com o suporte ou tente novamente mais tarde.",
            ),
          },
        });
        return;
      }
    } else if (membersFiltered.length !== members.length) {
      setMembersFiltered(members);
    }
  };

  return (
    <CampanhaRankingsStyled
      $isRenderedInSchedulePage={isRenderedInSchedulePage}
    >
      <div ref={scrollRef}></div>
      <h1>{i18n.message("rankingsCampanha.ranking.title", "Ranking")}</h1>
      <div class="botoes_select">{flexOpcoesControl(buttonOptions)}</div>
      {control === "todasAsEquipes" && (
        <>
          {!isRenderedInHomePage && (
            <LeadershipRanking topRanking={topRanking} />
          )}
          <div className="mt-4 mb-4">
            <RankingInputSearch
              placeholder={i18n.message(
                "rankingsCampanha.ranking.search.equipe",
                "Buscar por uma equipe",
              )}
              ariaLabel={i18n.message(
                "rankingsCampanha.ranking.search.equipe",
                "Buscar por uma equipe",
              )}
              onChange={changeSearch}
            ></RankingInputSearch>
          </div>

          {!loading ? (
            <div>
              <RankingListaEquipes paginacao={paginacao} rankings={rankings} />
            </div>
          ) : (
            <WeexSpinner />
          )}
          <RankingPaginacao
            page={paginacao.page}
            numberPages={numberPages}
            paginaAnterior={paginaAnterior}
            proximaPagina={proximaPagina}
          />
        </>
      )}
      {control === "suaEquipe" && uuidEquipe && (
        <>
          <RankingDaEquipe
            uuidEquipe={uuidEquipe}
            nomeEquipe={nomeEquipe}
            pontuacaoEquipe={pontuacaoEquipe}
            refreshEquipeInfo={onComponenteMount}
            setNomeEquipe={setNomeEquipe}
            members={membersFiltered}
            loading={loading}
            search={buscarParticipanteEquipe}
          />
        </>
      )}
    </CampanhaRankingsStyled>
  );
}
