import React, { useEffect, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import TeamService from "../../services/TeamService";
import CampanhaService from "../../services/CampanhaService";
import SelectTeamCard from "../../comps/team/SelectTeamCard";
import { SelectTeamStyle } from "./style";
import WeexFactory from "../../comps/WeexInputs/WeexFactory";
import AddUser from "../../comps/svg/team/AddUser";
import PrimarioBtn from "../../comps/buttonPWA2.0/PrimarioBtn";
import Plus from "../../comps/svg/botoes/Plus";

const teamService = new TeamService();

function SelectTeam() {
  const campanhaService = new CampanhaService();
  const campanhaCorrente = campanhaService.campanhaCorrente();
  const dispatch = useDispatch();
  const i18n = useStore().getState().i18n;
  const [teams, setTeams] = useState([]);
  const [timeout, setTimeout] = useState(false);
  const [page, setPage] = useState(0);
  const [name, setName] = useState("");
  const [leaderName, setLeaderName] = useState("");

  const getInTeam = (teamUuid = null, teamName = null) => {
    dispatch({ type: "SHOW_MODAL", payload: "entrarEquipe" });

    let payload = {
      teamUuid: null,
      teamName: null,
      title: i18n.message(
        "escolherEquipe.random.titulo",
        "Vamos escolher sua equipe para você!",
      ),
      btnCancelMessage: i18n.message(
        "escolherEquipe.random.btn.cancel",
        "Quero escolher minha própria equipe",
      ),
      btnConfirmMessage: i18n.message(
        "escolherEquipe.random.btn.confirm",
        "Podem escolher uma equipe para mim!",
      ),
    };
    if (teamUuid !== null) {
      payload = {
        teamUuid: teamUuid,
        teamName: teamName,
        title: i18n.message("escolherEquipe.titulo", "Você escolheu a equipe"),
        btnCancelMessage: i18n.message(
          "escolherEquipe.btn.cancel",
          "Escolher outra equipe",
        ),
        btnConfirmMessage: i18n.message(
          "escolherEquipe.btn.confirm",
          "Pode confirmar!",
        ),
      };
    }

    dispatch({
      type: "entrarEquipe",
      payload: payload,
    });
  };

  const getTeams = (carregarMais = false, searchFilters) => {
    teamService
      .getTeams(searchFilters, campanhaCorrente?.code)
      .then((response) => {
        if (response.data.teams) {
          const timesDisponiveis = response.data.teams;
          if (carregarMais) {
            setTeams([...teams, ...timesDisponiveis]);
          } else {
            setTeams(timesDisponiveis);
          }
        }
      })
      .catch((error) => {
        dispatch({ type: "SHOW_MODAL", payload: "error" });
        dispatch({
          type: "error",
          payload: {
            title: i18n.message(
              "selectTeam.erro.title",
              "Erro ao retornar lista de equipes",
            ),
            message: i18n.message(
              "selectTeam.erro.message",
              "Erro ao retornar lista de equipes. Por favor, tente novamente mais tarde ou entre em contato com o suporte.",
            ),
          },
        });
      });
  };

  useEffect(() => {
    const searchFilters = {
      pageSize: 12,
      page: 0,
      name: null,
      leaderName: null,
    };
    getTeams(false, searchFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTeams = () => {
    return (
      <div className="team__cardContainer">
        {teams.map((team) => {
          return (
            <div key={team?.uuid} className="team__card">
              <SelectTeamCard
                uuid={team?.uuid}
                name={team?.name}
                leader={team?.leader?.name}
                membersCount={team?.membersCount}
                score={team?.score}
                getInTeam={getInTeam}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const renderSearch = () => {
    return (
      <div className="team__searchContainer">
        <WeexFactory
          type="text"
          required={false}
          value={name}
          onKeyPress={handleKeyPress}
          onChange={alterarNomeBusca}
          placeholder={i18n.message(
            "selectTeam.busca.equipe.placeholder",
            "Buscar por equipe",
          )}
          icon="fas fa-search"
          iconposition="left"
        />
        <WeexFactory
          type="text"
          required={false}
          value={leaderName}
          onKeyPress={handleKeyPressLider}
          onChange={alterarLiderNomeBusca}
          placeholder={i18n.message(
            "selectTeam.busca.lider.placeholder",
            "Buscar por líder",
          )}
          icon="fa-regular fa-user"
          iconposition="left"
        />
      </div>
    );
  };

  const chooseRandomTeam = () => {
    return (
      <>
        <div>
          <PrimarioBtn
            id="botao-escolher-aleatorio"
            funcao={() => {
              getInTeam();
            }}
            nome={i18n.message(
              "selectTeam.aleatorio.button",
              "Escolha uma equipe para mim",
            )}
            disabled={false}
            disabledOnClick={false}
            type="button"
            ariaLabelMsg={i18n.message(
              "selectTeam.aleatorio.button",
              "Escolha uma equipe para mim",
            )}
            ariaStatusMessage={i18n.message(
              "ariaStatus.selectTeam.aleatorio.button",
              "Botão escolha uma equipe para mim clicado",
            )}
          />
        </div>{" "}
      </>
    );
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      alterarNomeBusca(event);
    }
  };

  const handleKeyPressLider = (event) => {
    if (event.key === "Enter") {
      alterarLiderNomeBusca(event);
    }
  };

  const alterarNomeBusca = (e) => {
    setName(e.target.value);
    const searchFilters = {
      pageSize: 12,
      name: e.target.value,
      leaderName: null,
    };

    if (timeout) {
      clearTimeout(timeout);
      setTimeout(false);
    }

    const newTimeout = setTimeout(() => {
      getTeams(false, searchFilters);
    }, 1000);

    setTimeout(newTimeout);
  };
  const alterarLiderNomeBusca = (e) => {
    setLeaderName(e.target.value);
    const searchFilters = {
      pageSize: 12,
      name: null,
      leaderName: e.target.value,
    };

    if (timeout) {
      clearTimeout(timeout);
      setTimeout(false);
    }

    const newTimeout = setTimeout(() => {
      getTeams(false, searchFilters);
    }, 1000);

    setTimeout(newTimeout);
  };

  const showMore = () => {
    let nextPage = page + 1;
    setPage(nextPage);
    const searchFilters = {
      pageSize: 12,
      page: nextPage,
      name: null,
      leaderName: null,
    };
    getTeams(true, searchFilters);
  };

  return (
    <SelectTeamStyle>
      <div className="team__iconContainer">
        <div className="team__icon">
          <AddUser />
        </div>
        <h1>{i18n.message("selectTeam.title", "Escolha sua equipe")}</h1>
      </div>
      <div className="team__controlsContainer">
        {renderSearch()}
        {chooseRandomTeam()}
        {<div className="team__desktopContainer"></div>}
      </div>
      {renderTeams()}
      <div className="team__showMore">
        <PrimarioBtn
          id="botao-vermais-times"
          funcao={showMore}
          nome={i18n.message("selectTeam.btn.vermais.times", "Ver mais")}
          disabled={false}
          disabledOnClick={false}
          ariaLabelMsg={i18n.message(
            "selectTeam.btn.vermais.times",
            "Ver mais",
          )}
          ariaStatusMessage={i18n.message(
            "ariaStatus.selectTeam.btn.vermais.times",
            "Ver mais clicado",
          )}
          iconposition="right"
          iconRight={
            <>
              <Plus />
            </>
          }
        />
      </div>
    </SelectTeamStyle>
  );
}

export default SelectTeam;
