import React, { useEffect, useMemo, useState } from "react";
import { useStore, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Contador from "../../comps/contador/Contador";
import CampanhaService from "../../services/CampanhaService";
import MensagemVideoService from "../../services/MensagemVideoService";
import TemaService from "../../services/TemaService";
import { HomeStyle } from "./style";
import UsuarioService from "../../services/UsuarioService";
import PrimarioBtn from "../../comps/buttonPWA2.0/PrimarioBtn";
import SecundarioBtn from "../../comps/buttonPWA2.0/SecundarioBtn";
import CampanhaRankingsPorEquipe from "../campanhaRanking/CampanhaRankingsPorEquipe";
import AgendaService from "../../services/AgendaService";

function Home() {
  const mensagemVideoService = new MensagemVideoService();
  const navigate = useNavigate();
  const i18n = useStore().getState().i18n;
  const dispatch = useDispatch();
  const campanhaService = useMemo(() => new CampanhaService(), []);
  const campanha = campanhaService.campanhaCorrente();
  const [erro, setErro] = useState(null);
  const usuarioService = new UsuarioService();
  const adminOrGestor = usuarioService.isAdmin() || usuarioService.isGestor();
  let isCampanhaComEquipe = campanha.campaignType === "EQUIPE";
  const agendaService = new AgendaService();
  const [habilitarBotaoInteresse, setHabilitarBotaoInteresse] = useState(true);

  let ocultarRanking = false;
  if (campanha) {
    if (campanha.personalizationEvent) {
      ocultarRanking = campanha.personalizationEvent?.HIDE_RANKING === "true";
      if (ocultarRanking) {
        isCampanhaComEquipe = false;
      }
    }
  }

  useEffect(() => {
    dispatch({ type: "mainBackgroundColor", payload: "#ffffff" });
    const sincronizarCampanha = async () => {
      try {
        await campanhaService.useSincronizarCampanha();
      } catch (error) {
        console.error(error);
      }
    };

    sincronizarCampanha();
    let temaService = new TemaService(campanhaService.campanhaCorrente());
    temaService.aplicarTema("HOME");

    mensagemVideoService.obterMensagemDeVideo((error, sucesso) => {
      if (error) {
        setErro(
          i18n.message(
            "atividades.erro.video.prefixo",
            "Erro ao obter mensagem de video da campanha: ",
          ) + erro,
        );
        return;
      }
      if (sucesso) {
        if (sucesso.length > 0) {
          dispatch({ type: "messageVideo", payload: sucesso });
          dispatch({ type: "SHOW_MODAL", payload: "welcomeVideo" });
        }
      }
    });

    if (adminOrGestor) {
      navigate("/atividades");
      return;
    }

    campanhaService.contadorCampanha((erro, sucesso) => {
      if (erro) {
        return erro;
      }
      if (sucesso) {
        if (sucesso.length > 0) {
          dispatch({ type: "campanhaComecou", payload: true });
          navigate("/atividades");
        }
      }
    });

    let agenda = null;
    if (agendaService.obterAgendaStorage()) {
      const result = agendaService.obterAgendaStorage();
      if (result) {
        agenda = result;
      }
    } else {
      agendaService.agendaCorrente((_erro, result) => {
        if (result) {
          agenda = result;
        }
      });
    }

    if (agenda != null) {
      if (agenda.mandatoryInterestParticipant !== null) {
        const hasInterests = agenda.interests && agenda.interests.length > 0;
        setHabilitarBotaoInteresse(!hasInterests);
      }
    }
    // TODO: Ao colocar as dependencias solicitadas pelo Lint, a aplicação fica em loop infinito no useEffect --> realacionada tarefa 14797 --> By Renan
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let inicioCampanhaContador = null;
  if (campanha && campanha.startDate) {
    const data = campanha.startDate.split("/");
    let horas = data[2].split(" ");
    data[2] = horas[0];
    horas = horas[1].split(":");

    inicioCampanhaContador = new Date(
      parseInt(data[2]),
      parseInt(data[1]) - 1,
      parseInt(data[0]),
      parseInt(horas[0]),
      parseInt(horas[1]),
      parseInt(horas[2]),
    ).getTime();
  }

  const botaoIntesseMensagem = () => {
    if (habilitarBotaoInteresse) {
      return i18n.message("home.interesse.definir", "DEFINA SEUS INTERESSES");
    }
    return i18n.message("home.interesse.definidos", "INTERESSES DEFINIDOS");
  };

  return (
    <HomeStyle>
      <div className="weex__content-home">
        <div className="info-campanha-container-interesse">
          <br />
          <h1>{i18n.message("home.datainicio", "Faltam")}</h1>
        </div>
        <div className="contador-campanha-container-interesse">
          <div>
            {inicioCampanhaContador !== null ? (
              <Contador
                inicioCampanha={inicioCampanhaContador}
                home={true}
              ></Contador>
            ) : (
              ""
            )}
          </div>

          <div className="info-campanha-container-interesse">
            <br />
            <h3>{i18n.message("home.dataFim", "para começar!")}</h3>
          </div>
          <div className="weex-l-contador-botoes">
            {campanha &&
            campanha?.comoFunciona &&
            campanha.comoFunciona?.helpMessage ? (
              <div className="content-botoes">
                <PrimarioBtn
                  classeComplementar="home-btn-padding-como-funciona"
                  nome={i18n.message(
                    "home.instrucoes",
                    "CONFIRA COMO FUNCIONA",
                  )}
                  funcao={() => navigate("/help")}
                  ariaStatusMessage={i18n.message(
                    "aria.status.home.botaoconfiracomofunciona",
                    "Botao confira como funciona clicado.",
                  )}
                />
              </div>
            ) : (
              ""
            )}

            {campanha.requiredInterests ? (
              <div className="content-botoes">
                <SecundarioBtn
                  iconposition="right"
                  disabled={!habilitarBotaoInteresse}
                  nome={botaoIntesseMensagem()}
                  funcao={() => navigate("/interests")}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {isCampanhaComEquipe && (
        <div className="atividades__containerRanking">
          <div className="atividades__containerRanking--size">
            <CampanhaRankingsPorEquipe
              isRenderedInSchedulePage={true}
              isRenderedInHomePage={true}
            />
          </div>
        </div>
      )}
    </HomeStyle>
  );
}

export default Home;
