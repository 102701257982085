import React from "react";
import { WeexDialog } from "../../../comps/weexModais/WeexDialog";
import BotaoFechar from "../../../comps/BotaoFechar/BotaoFechar";
import PrimarioBtn from "../../../comps/buttonPWA2.0/PrimarioBtn";
import styled from "styled-components";
import PropTypes from "prop-types";

// Styled components
const ModalContainer = styled.div`
  li {
    margin-bottom: 10px;
  }
`;

const NotificationModal = ({ dialogRef, ocultar }) => {
  const i18n = { message: (key, value) => `${key} - ${value}` }; // Exemplo de função de tradução

  // Função que vai renderizar o conteúdo do modal
  const firstPageInfos = () => {
    return (
      <>
        <h1 className="weexDialog__texts--alignment">Aviso de Notificação</h1>
        <section className="weexDialog__textsContainer">
          <span className="weexDialog__texts--alignment">
            Você não aceitou as notificações do navegador. Para poder recebers
            notificações, siga as instruções abaixo para o seu navegador:
          </span>
          <ul>
            <li>
              <a
                href="https://support.mozilla.org/pt-BR/kb/notificacoes-web-push-firefox"
                target="_blank"
                rel="noopener noreferrer"
              >
                Ativar notificações no Firefox
              </a>
            </li>
            <li>
              <a
                href="https://support.google.com/chrome/answer/3220216?hl=pt-BR"
                target="_blank"
                rel="noopener noreferrer"
              >
                Ativar notificações no Chrome
              </a>
            </li>
            <li>
              <a
                href="https://support.apple.com/pt-br/guide/mac-help/mchl39cc046c/mac#:~:text=Consulte%20Ativar%20ou%20desativar%20um,seja%20necess%C3%A1rio%20rolar%20para%20baixo)."
                target="_blank"
                rel="noopener noreferrer"
              >
                Ativar notificações no Safari
              </a>
            </li>
          </ul>
        </section>
        <section className="weexDialog__buttonsContainer weexDialog__buttonsContainer--direction weexDialog__buttonsContainer--margins">
          <PrimarioBtn
            nome="Fechar"
            funcao={ocultar}
            id={"botao-equipe-fechar"}
            disabledOnClick={false}
            ariaLabelMsg="Fechar"
            ariaStatusMessage={i18n.message(
              "ariaStatus.escolherEquipe.btn.close",
              "Fechar clicado",
            )}
          />
        </section>
      </>
    );
  };

  const dialogStyle = {
    minHeight: "auto",
    backgroundColor: "white",
    borderRadius: "8px",
    margin: "auto",
  };

  return (
    <ModalContainer>
      <WeexDialog
        styled={dialogStyle}
        dialogRef={dialogRef}
        onPressESC={ocultar}
      >
        <BotaoFechar funcao={ocultar} />
        <main className="weexDialog__main">{firstPageInfos()}</main>
      </WeexDialog>
      ;
    </ModalContainer>
  );
};
NotificationModal.propTypes = {
  dialogRef: PropTypes.object.isRequired,
  ocultar: PropTypes.func.isRequired,
};

export default NotificationModal;
