import React, { useCallback, useEffect } from "react";
import { WeexDialogStyle } from "./style";

/**
 * Novo Modal Dialog para a Weex 3.0.
 *
 * @throws Erro quando onPressESC não é implementado, o forçando a ser implementado
 *
 * @Aviso Quando o usuário aperta `esc`, a função `onPressESC` é chamada. Use isso para permitir que um componente
 * pai identifique quando o filho foi fechado através do pressionamento da tecla `esc`.
 *
 * É recomendado que a condição do operador ternario ou binário responsável pela renderização desse componente
 * seja um estado fornecido através do hook `useState`, permitindo com que através do callback `onPressESC` o
 * componente pai possa controlar adequadamente quando o componente `WeexDialog` deve ser renderizado,
 * evitando assim bugs de usabilidade, pois WeexDialog utiliza nos bastidores o método da tag `<dialog />`
 * chamado `.close()`, que faz com que a modal fique apenas invisível, mas continue dentro do html de quem esteja
 * navegando.
 *
 * @example
 * const [isDialogOpen, setIsDialogOpen] = useState(false)
 *
 * isDialogOpen && (
 * 		<WeexDialog
 * 			onClose={() => {
 * 				setIsDialogOpen(false)
 * 			}}
 * 		>
 * 			<button onClick={() => {
 * 				setIsDialogOpen(false)
 * 			}}>
 * 				Close
 * 			</button>
 * 		</WeexDialog>
 * )
 *
 * @Aviso Como `WeexDialog` permite a passagem de componentes filhos, caso algum componente filho tenha que
 * possuir a funcionalidade de fechar essa modal, é recomendado que esse componente filho set diretamente o estado
 * da condicional que definirá se `WeexDialog` deveria ser renderizado ou não, como no botão ao exemplo acima.
 *
 * @param {Object} props
 * @param {function(): void} props.onPressESC
 * @param {React.MutableRefObject} props.dialogRef
 * @param {JSX.Element} props.children
 */
export function WeexDialog({ onPressESC, dialogRef, children, styled }) {
  function onCancel(e) {
    e.preventDefault();
    const { current } = dialogRef;
    current.close();
    onPressESC();
  }

  const openDialog = useCallback(() => {
    const { current } = dialogRef;
    current.close();
    current.showModal();
  }, [dialogRef]);

  useEffect(() => {
    openDialog();
  }, [openDialog]);

  return (
    <WeexDialogStyle ref={dialogRef} onCancel={onCancel} style={styled}>
      {children}
    </WeexDialogStyle>
  );
}
