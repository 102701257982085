import React from "react";
import { WeexInput } from "./style";

function WeexCheckboxInput({
  name,
  id,
  required = false,
  disabled = false,
  onChange,
  ariaLabel,
  label,
}) {
  return (
    <WeexInput>
      <label
        htmlFor={id}
        aria-label={ariaLabel}
        className="weexInput__checkbox--label"
      >
        <input
          className="weexInput__checkbox"
          type="checkbox"
          id={id}
          name={name}
          required={required}
          disabled={disabled}
          onChange={onChange}
        />
        <div>{label}</div>
      </label>
    </WeexInput>
  );
}

export default WeexCheckboxInput;
