import styled from "styled-components";

export const AlternativaContainer = styled.div`
  position: relative;
  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    margin-top: 5rem;
  }
  /* #endregion DESKTOP */
`;

export const AlternativaStyle = styled.div`
  margin-bottom: 1rem;
  width: 100%;

  .weex-l-acerteTempo-alternativa {
    display: inline-block;
    position: relative;
    width: 100%;
  }

  .weex-l-acerteTempo-alternativa input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .weex-l-acerteTempo-alternativa,
  .weex-l-acerteTempo-alternativa--label {
    border: 1px solid
      ${(props) => {
        return props.theme.fundoSecundario;
      }};
    border-radius: 0.5rem;
    background-color: #ffffff;
    color: ${(props) => {
      return props.theme.fundoSecundario;
    }};
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .weex-l-acerteTempo-verticalLine {
    border: 1px solid
      ${(props) => {
        return props.theme.fundoSecundario;
      }};
    height: 4rem;
  }

  .weex-l-acerteTempo-alternativa--acertou,
  .weex-l-acerteTempo-alternativa--label--acertou {
    span {
      color: #4a4a4a;
      p {
        color: #4a4a4a;
      }
    }
    color: #4a4a4a;
    background-color: #00e291;
    border: 1px solid #00e291;
  }

  .weex-l-acerteTempo-alternativa--errou,
  .weex-l-acerteTempo-alternativa--label--errou {
    span {
      color: #ffffff;
      p {
        color: #ffffff;
      }
    }
    color: #ffffff;
    background-color: #fe001a;
    border: 1px solid #fe001a;
  }

  .weex-l-acerteTempo-alternativa--correta,
  .weex-l-acerteTempo-alternativa--label--correta {
    span {
      color: #00e291;
      p {
        color: #00e291;
      }
    }
    color: #00e291;
    border: 1px solid #00e291;
  }

  .weex-l-acerteTempo-verticalLine--errou {
    border: 1px solid #ffffff;
  }

  .weex-l-acerteTempo-verticalLine--acertou {
    border: 1px solid #66fffb;
  }

  .weex-l-acerteTempo-verticalLine--correta {
    border: 1px solid #00e291;
  }

  .weex-l-acerteTempo-alternativa--enunciado {
    p,
    span {
      font-size: 1rem;
      line-height: 1.2rem;
      font-weight: 700;
      color: ${(props) => {
        return props.theme.fundoSecundario;
      }};
    }
    padding: 1rem;
  }

  .weex-l-acerteTempo-alternativa--alfabeto {
    color: ${(props) => {
      return props.theme.fundoSecundario;
    }};
    padding: 1rem;
    font-size: 1.5rem;
    font-weight: 700;
  }

  // para as p tags que vem do backend
  p {
    font-size: 0.8rem;
    line-height: 0.8rem;
    font-weight: 700;
    text-align: left;
  }

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
    .weex-l-acerteTempo-alternativa--label&:hover,
    .weex-l-acerteTempo-alternativa--label&:active {
      filter: opacity(0.6);
    }
  }
  /* #endregion DESKTOP */
`;

export const GifAcertouStyle = styled.div`
  width: 100%;
  height: 20rem;
  background: url(/coruja_feliz.gif) center center no-repeat;
  background-size: cover;
  position: absolute;
  bottom: 0rem;
  left: 0rem;
  z-index: 2;

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
  }
  /* #endregion DESKTOP */
`;

export const GifErrouStyle = styled.div`
  width: 100%;
  height: 20rem;
  background: url(/coruja_triste.gif) center center no-repeat;
  background-size: cover;
  position: absolute;
  bottom: 0rem;
  left: 0rem;
  z-index: 2;

  /* #region DESKTOP */
  /* 75rem é o equivalente a 1200px */
  @media (min-width: 75rem) {
  }
  /* #endregion DESKTOP */
`;
