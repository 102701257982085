import instance from "./Api";

export default class FirebaseService {
  notificadorUrl = process.env.REACT_APP_HOST_NOTIFICADOR;

  enviarTokenParaNotificador({ token, userId, name, enrollment }) {
    const body = {
      token: token,
      userId: userId,
      name: name,
      enrollment: enrollment,
    };

    return instance.post(this.notificadorUrl + "/tokens", body);
  }

  marcarMensagemComoLida(mensagemId, data) {
    const body = {
      readDate: data,
    };

    instance
      .put(`${this.notificadorUrl}/mensagens/${mensagemId}/read`, body)
      .then((response) => {
        console.log("Mensagem marcada como lida com sucesso");
      });
  }

  buscarMensagensParticipante({ userId, status, event, page, limit }) {
    const searchParams = new URLSearchParams();
    searchParams.append("participantUUID", userId);

    if (status) {
      searchParams.append("status", status);
    }

    if (page) {
      searchParams.append("page", page);
    }

    if (limit) {
      searchParams.append("limit", limit);
    }

    if (event) {
      searchParams.append("event", event);
    }

    return instance.get(`${this.notificadorUrl}/mensagens`, {
      params: searchParams,
    });
  }

  removerTokenDeNotificador(token) {
    const body = {
      token: token,
    };
    return instance.delete(`${this.notificadorUrl}/tokens`, { data: body });
  }
}
