// DynamicScripts.jsx
import { useEffect } from "react";
import DOMPurify from "dompurify";

const parseScriptContent = (scriptString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(scriptString, "text/html");

  const scriptTag = doc.querySelector("script");
  const noscriptTag = doc.querySelector("noscript");

  let scriptContent = "";
  let noscriptContent = "";

  if (scriptTag || noscriptTag) {
    scriptContent = scriptTag ? DOMPurify.sanitize(scriptTag.innerHTML) : "";
    noscriptContent = noscriptTag
      ? DOMPurify.sanitize(noscriptTag.innerHTML)
      : "";
  } else {
    scriptContent = DOMPurify.sanitize(scriptString);
  }

  return { scriptContent, noscriptContent };
};

const DynamicScripts = ({ scripts }) => {
  useEffect(() => {
    const footerScripts = scripts.filter((script) => script.type === "FOOTER");

    footerScripts.forEach((scriptItem) => {
      const { scriptContent, noscriptContent } = parseScriptContent(
        scriptItem.script,
      );

      // Injetar o script no footer
      if (scriptContent) {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.async = true;
        script.innerHTML = scriptContent;
        script.setAttribute("data-uuid", String(scriptItem.uuid));

        // Verifica se o script já foi adicionado para evitar duplicatas
        if (
          !document.body.querySelector(`script[data-uuid="${scriptItem.uuid}"]`)
        ) {
          document.body.appendChild(script);
        }
      }

      // Injetar o noscript no footer
      if (noscriptContent) {
        const noscriptDiv = document.createElement("div");
        noscriptDiv.setAttribute(
          "data-uuid",
          `${String(scriptItem.uuid)}-noscript`,
        );
        noscriptDiv.innerHTML = noscriptContent;

        if (
          !document.body.querySelector(
            `div[data-uuid="${scriptItem.uuid}-noscript"]`,
          )
        ) {
          document.body.appendChild(noscriptDiv);
        }
      }
    });

    // Função de limpeza para remover os scripts ao desmontar o componente
    return () => {
      footerScripts.forEach((scriptItem) => {
        const script = document.body.querySelector(
          `script[data-uuid="${scriptItem.uuid}"]`,
        );
        if (script) {
          document.body.removeChild(script);
        }

        const noscriptDiv = document.body.querySelector(
          `div[data-uuid="${scriptItem.uuid}-noscript"]`,
        );
        if (noscriptDiv) {
          document.body.removeChild(noscriptDiv);
        }
      });
    };
  }, [scripts]);

  useEffect(() => {
    const headerScripts = scripts.filter((script) => script.type === "HEADER");

    headerScripts.forEach((scriptItem) => {
      const { scriptContent } = parseScriptContent(scriptItem.script);

      // Injetar o script no header
      if (scriptContent) {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.async = true;

        const srcMatch = scriptContent.match(/src=["']([^"']+)["']/);
        if (srcMatch && srcMatch[1]) {
          script.src = srcMatch[1];
        } else {
          script.innerHTML = scriptContent;
        }

        script.setAttribute("data-uuid", String(scriptItem.uuid));

        if (
          !document.head.querySelector(`script[data-uuid="${scriptItem.uuid}"]`)
        ) {
          document.head.appendChild(script);
        }
      }
    });

    // Função de limpeza para remover os scripts do header ao desmontar o componente
    return () => {
      headerScripts.forEach((scriptItem) => {
        const script = document.head.querySelector(
          `script[data-uuid="${scriptItem.uuid}"]`,
        );
        if (script) {
          document.head.removeChild(script);
        }
      });
    };
  }, [scripts]);

  return null;
};

export default DynamicScripts;
