import React, { useEffect, useRef, useState } from "react";
import NotificationModal from "./comps/ModalPermicaoNotificacao";
import NotificationList from "./comps/ListNotificacao";

const Notificacao = () => {
  const [permission, setPermission] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const dialogRef = useRef(null);

  const ocultar = () => {
    setShowModal(false);
    dialogRef.current.close();
  };

  useEffect(() => {
    console.log("Notificação: Operação concluída com sucesso!");
    // Verificar se o navegador suporta notificações
    if (!("Notification" in window)) {
      console.log("Este navegador não suporta notificações de desktop.");
      window.alert("Este navegador não suporta notificações de desktop.");
      return;
    }

    // Verificar o status da permissão
    if (Notification.permission === "granted") {
      setPermission(true);
    } else if (Notification.permission === "denied") {
      setPermission(false);
      setShowModal(true);
    }
  }, []);

  return (
    <div>
      <h1>Suas Notificações</h1>
      {!permission && showModal && (
        <NotificationModal
          dialogRef={dialogRef}
          ocultar={ocultar}
        ></NotificationModal>
      )}

      <NotificationList></NotificationList>
    </div>
  );
};

export default Notificacao;
