import { initializeApp } from "firebase/app";
import {
  deleteToken,
  getMessaging,
  getToken,
  onMessage,
} from "firebase/messaging";
import FirebaseService from "./services/FirebaseService";
import UsuarioService from "./services/UsuarioService";

// Configuração do Firebase
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
// Chave VAPID (opcional, para web push)

const vapidKey = process.env.REACT_APP_VAPID_KEY;

const app = initializeApp(firebaseConfig);

const getMessagingInstance = () => {
  return getMessaging(app);
};

let loading = false;

const setLoading = (value) => {
  loading = value;
};

export const deleteTokenFromFirebase = async () => {
  console.log("Deletando token do Firebase");

  const firebaseService = new FirebaseService();
  const messaging = getMessagingInstance();
  const token = localStorage.getItem("firebaseToken");

  try {
    // Remover token do notificador, se existir
    if (token) {
      await firebaseService.removerTokenDeNotificador(token);
      console.log("Token removido do notificador com sucesso");
    }

    // Remover token do Firebase Messaging
    await deleteToken(messaging);
    console.log("Token removido do Firebase Messaging");

    // Remover token do localStorage
    localStorage.removeItem("firebaseToken");
    console.log("Token removido do localStorage");
  } catch (error) {
    console.error("Erro ao deletar token:", error);
  }
};
export const requestNotificationPermission = () => {
  const firebaseService = new FirebaseService();
  const messaging = getMessagingInstance();
  const usuario = new UsuarioService().usuarioCorrente();
  if (!usuario) {
    console.error("Usuário não encontrado");
    return;
  }
  return Notification.requestPermission().then((permission) => {
    if (loading) {
      return;
    }
    if (permission === "granted") {
      setLoading(true);
      console.log("Permissão concedida");
      return getToken(messaging, { vapidKey })
        .then((token) => {
          console.log("Token recebido:", token);
          if (localStorage.getItem("firebaseToken") === token) {
            return token;
          }
          firebaseService
            .enviarTokenParaNotificador({
              token,
              userId: usuario.participantCode,
              name: usuario.name ? usuario.name : usuario.email,
              enrollment:
                usuario.enrollment !== null
                  ? usuario.enrollment
                  : usuario.email,
            })
            .then(
              (response) => {
                localStorage.setItem("firebaseToken", token);
                setLoading(false);
                return token;
              },
              (error) => {
                setLoading(false);
                console.error("Erro ao enviar token:", error);
              },
            );
        })
        .catch((err) => {
          console.error("Unable to get token", err);
        });
    } else {
      return null;
    }
  });
};

// Solicitar permissão de notificação
export const requestPermission = async () => {
  const usuario = new UsuarioService().usuarioCorrente();

  if (usuario) {
    try {
      const token = await requestNotificationPermission();
      if (token) {
        localStorage.setItem("firebaseToken", token);
      }
    } catch (error) {
      console.error("Erro ao solicitar permissão:", error);
    }
  }
};

// Função para lidar com uma única mensagem recebida usando Promise
export const onMessageListener = () => {
  const messaging = getMessagingInstance();
  return new Promise((resolve, reject) => {
    try {
      onMessage(messaging, (payload) => {
        console.log("Mensagem recebida. Payload:", payload);
        resolve(payload);
      });
    } catch (error) {
      console.error("Erro ao configurar o listener de mensagem:", error);
      reject(error);
    }
  });
};

// Configurar listener de mensagens com suporte a callback
export const setupMessageListener = (callback) => {
  const messaging = getMessagingInstance();

  onMessage(messaging, (payload) => {
    try {
      // Se um callback for fornecido, executa-o
      if (typeof callback === "function") {
        callback(payload);
      }
    } catch (err) {
      console.error("Erro ao processar mensagem:", err);
    }
  });
};
