import React, { useState } from "react";
import { WeexInput, Label, Input } from "./style";
import { InputFormatter } from "../authInscricao/campoCustomizados/mask";

/**
 * Creates a new Weex input component.
 * @param {string} type - The type of input, such as "text", "number", "password", etc.
 * @param {string} name - The name of the input.
 * @param {string} id - The unique ID of the input.
 * @param {string} placeholder - The placeholder text for the input.
 * @param {string} label - The label text for the input.
 * @param {boolean} [required=true] - Whether the input is required or not.
 * @param {boolean} [disabled=false] - Whether the input is disabled or not.
 * @param {function} onChange - A function that is called when the input value changes.
 * @param {string} icon - The icon to display next to the input.
 * @param {string} iconposition - The position of the icon relative to the input. Can be "left" or "right".
 * @returns {JSX.Element} The Weex input component.
 */
function WeexMaskInput({
  key,
  type,
  name,
  id,
  placeholder,
  label,
  required = true,
  disabled = false,
  onChange,
  icon,
  iconposition = "left",
  pattern,
  onInvalid,
  ariaLabel,
  onKeyPress,
  mask,
}) {
  const [value, setValue] = useState("");
  const formatter = new InputFormatter();
  if (mask !== null) {
    formatter.addPattern(name, mask);
  }
  const className =
    iconposition === "right"
      ? "weexInput__icon weexInput__icon--right"
      : "weexInput__icon";

  const onChangeHandler = (event) => {
    const valueEvent = event.target.value;
    if (mask && valueEvent !== null) {
      const valueFormatted = formatter.format(valueEvent, mask, name);
      setValue(valueFormatted);
      onChange(formatter.clean(valueFormatted));
    } else {
      setValue(valueEvent);
      onChange(valueEvent);
    }
  };

  return (
    <WeexInput key={key}>
      <Label htmlFor={id} aria-label={ariaLabel}>
        {label}
        <Input
          id={id}
          type={type}
          name={name}
          pattern={pattern}
          placeholder={placeholder}
          required={required}
          disabled={disabled}
          onChange={onChangeHandler}
          $isright={iconposition === "right" ? "true" : "false"}
          onInvalid={onInvalid}
          onKeyPress={onKeyPress}
          value={value}
        />
        {icon && (
          <div className={`${className}`}>
            <i className={icon}></i>
          </div>
        )}
      </Label>
    </WeexInput>
  );
}

export default WeexMaskInput;
